.summary-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}

.summary-contact-info{
    width: 95%;
    text-align: left;
    margin-bottom: 0.5rem;
}

.summary-grid-container{
    display: grid;
    width: 95%;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    gap: 0.5rem;
}

.summary-recaptcha-container{
    margin-top: 1.5rem;
    margin-bottom: 5rem;
    height: 20px;
}

@media screen and (min-width: 360px) {

    .summary-grid-container{
        gap: 1rem;
    }
}

@media screen and (min-width: 400px) {
    .summary-contact-info{
        width: 93%;
        max-width: 400px;
        margin-bottom: 1rem;
        margin-left: 4rem;
    }
    
    .summary-grid-container{
        width: 93%;
        max-width: 400px;
        margin-left: 4rem;
    }
}

@media screen and (min-width: 700px) {

    .summary-contact-info{
       text-align: center;
       margin-left: 0;
    }
    .summary-grid-container{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: 650px;
        margin-left: 0;
    }
    .summary-recaptcha-container{
        margin-top: 1.5rem;
        height: 100%;
    }
}

@media screen and (min-width: 1100px) {
    .summary-contact-info{
     
    }
    .summary-grid-container{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: 900px;
        
    }
}