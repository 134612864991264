.error-bo-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: var(--bo-bg-color);
}

.error-bo-logo-container{
    margin: 1.5rem 0 1.5rem 0;
    width: 40%;
    max-width: 150px;
}

.error-bo-logo{
    width: 100%;
}

.error-bo-text-container{
    padding: 0 0.5rem;
    max-width: 600px;    
}

.error-bo-main-header{
    margin-bottom: 1rem;
    color: var(--dark-color)
}

.error-bo-p{
    font-size: var(--text-body-size);
    color: var(--dark-color)
}

.error-bo-sub-header{
    text-align: center;
    font-size: var(--text-body-header-size);
    color: var(--dark-color);
    margin-bottom: 1rem;
}

.error-bo-code-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    
}

.error-bo-code-label{
    color: var(--dark-color);
}

.error-bo-code{
    color: var(--dark-color);
    font-size: var(--text-body-small-size);
    text-align: center;
    background-color: var(--grey-color);
    padding: 0.1rem 0.5rem;
    border-radius: 5px;
    margin-bottom: 0.5rem;

}

.error-bo-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    border-radius: 15px;
    width: 200px;
    height: 2.4rem;
    font-weight: var(--text-body-header-weight);
    background-color: var(--main-color);
}

.error-bo-btn-text{
    color: var(--dark-color);

}

@media screen and (min-width: 700px) {
    .error-bo-p{
        font-size: 1rem;
    }
}

