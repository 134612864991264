.start-main-container{
    text-align: center;
    width: 100%;
    height: 100%;
}

.start-img-container{
    position: relative;   
    width: 100%;
    height: 45%;
    overflow: hidden;
}

.start-logo-container{
    position: absolute;
    width: 35%;
    max-width: 160px;
    top: 1%;
    left: 3%;
    z-index: 2;
    object-fit: scale-down;
}

.start-img{
    position: absolute;
    width: 100%;
    height: 170%;
    top: -15px
}

.start-text-container{
    width: 100%;
    padding: 0 1rem;
}

.start-recaptcha-container{
    margin-left: 2rem;
}

.start-header{
    margin: 1rem 0 0.3rem;
}

.start-body{
    margin-bottom: 0.5rem;
}


@media screen and (min-width: 375px) {
    
    .start-text-container{
        width: 100%;
        padding: 0 1rem;
    }
    
    .start-header{
        margin: 1.2rem 0 0.5rem;
    }
    
    .start-body{
        margin-bottom: 1rem;
    }
    
}

@media screen and (min-width: 500px) {
    
    .start-text-container{
        width: 100%;
        padding: 0 3rem;
    }

    .start-img{
        position: absolute;
        width: 100%;
        height: 240%;
        top: -139px
    }
    
    .start-header{
        margin: 1rem 0 0.5rem;
    }
    
    .start-body{
        margin-bottom: 1rem;
    }
    
}

@media screen and (min-width: 700px) {
    .start-main-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
    }
    
    .start-logo-container{
        max-width: 150px;
        object-fit: cover;
    }

    .start-img-container{
        height: 100%;
        object-fit: fill;
    }
    
    .start-img{
        height: 1200px;
        top: 0px;
        left: 0px;
    }
    
    .start-text-container{
        padding: 0 2rem;
        margin-bottom: 3rem;
    }
    
    .start-header{
        margin: 1rem 0 1.2rem;
    }
    
    .start-body{
        margin-bottom: 1.2rem;
    }
  }

  @media screen and (min-width: 1000px) {
    
    .start-text-container{
        padding: 0 8rem 0 2rem;
        margin-bottom: 3rem;
    }

    .start-img{
        height: 1000px;
    }
    
    .start-header{
        margin: 1rem 0 1.2rem;
    }
    
    .start-body{
        margin-bottom: 1.2rem;
    }
  }

  