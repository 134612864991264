.uploadphoto-container{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    width: 98%;
    max-width: 343px;
    height: 100%;
    min-height: 43px;
    background-color: black;
    border: 3px var(--main-color) solid;
    border-radius: 18px;
    cursor: pointer;
    margin-left: 3px;
    margin-top: 0.2rem; 
}

#displayed-img{
    grid-area: 1 / 2 / 2 / 4;
    font-size: var(--text-body-size);
}

.no-photo-container{
    width: 100%;
    grid-area: 1 / 1 / 2 / 5;
}

.clear-photo-icon-container{
    grid-area: 1 / 4 / 2 / 5;
    margin-left: 0.6rem;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 3;
}

@media screen and (min-width: 360px) {
    .no-photo-container{
        height: 3.2rem;
        margin-left: 0;
        margin-top: 0.2rem;
    }
}
  
@media screen and (min-width: 700px) {
    .no-photo-container{
        height: 3.8rem;
    }
}

@media screen and (min-width: 1150px) {
    .uploadphoto-container{
        height: 6.2rem; 
    }

    .uploadphoto-container.with-photo{
        height: 135px;
    }
}