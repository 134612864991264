.container-bo-btn{
    display: flex;
    justify-content: center;
    height: 2rem;
    width: auto;
    background-color: var(--bo-bg-color);
    border: 1.5px var(--bo-main-color) solid;
    border-radius: 3px;
    font-size: var(--text-body-size);
    font-weight: var(--text-body-weight);
    padding: 0.3rem 0.5rem;
    cursor:pointer;
}

.content-bo-btn{
    grid-area: 1 / 1 / 2 / 5;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    z-index: 2;
}

.text-bo-btn{
    color: var(--dark-color);
    font-size: var(--text-body-small-size);
    font-weight: var(--text-main-btn-weight);
}