.header-logo-backstep-main-container{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:100%;
}

.header-logo-backstep-logo-container{
    display: none;
}

.header-logo-backstep-header-container{
    margin: 15% 0 1rem 0;
}

@media screen and (min-width: 360px) {
    .header-logo-backstep-main-container{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;
    }

    .header-logo-backstep-logo-container{
        display: inline;
        margin-top: 3%;
        width: 35%;
        max-width: 100px;
    }

    .header-logo-backstep-header-container{
        margin: 0.5rem 0 1rem 0;
    }
  }

  @media screen and (min-width: 400px) {
    .header-logo-backstep-main-container{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;
    }

    .header-logo-backstep-logo-container{
        display: inline;
        margin-top: 4%;
        width: 35%;
        max-width: 125px;
    }

    .header-logo-backstep-header-container{
        margin: 0.5rem 0 1rem 0;
    }
  }

@media screen and (min-width: 700px) {
    .header-logo-backstep-logo-container{
        max-width: 200px;
    }
    .header-logo-backstep-header-container{
        margin: 1rem 0 2rem 0;
    }
  }
  

  

