.main-btn-icon-container{
    height: 1rem;
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
}

.main-btn{
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    border-radius: 20px;
    border: var(--dark-color) 3px solid;
    color: var(--dark-color);
    font-weight: var(--text-main-btn-weight);
    font-size: var(--text-main-btn-size);
}
.main-btn:disabled {
    background-color: var(--main-color-light);
    opacity: 0.5;
}

@media screen and (min-width: 375px) {
    .main-btn-icon-container{
        width: 1.5rem;
        height: 1.5rem;
        margin-bottom: 0;
    }
  }