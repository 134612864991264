.backstep-container{
    position: absolute;
    display: flex;
    align-items: center;
    top: 10%;
    left: 10px;
}

.header-logo-backstep-main-container a:focus {
    outline: auto;
}

.arrow-back{
    margin-right: 0.2rem;

}

.backstep{
    display: inline;
    font-size: var(--text-body-size);
    font-weight: var(--text-body-weight);
    color: var(--text-accent);
}

@media screen and (min-width: 700px) {
    .arrow-back{
        size: 1.2rem;
    }
  }
 
