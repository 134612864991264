input, label {
    display:block;
}

#obstacle-place,
#obstacle-desc,
#name,
#email,
#phone,
#login-username,
#login-password{
    grid-area: 1 / 1 / 2 / 5;
}
.input-container{
    position: relative;
}
.input-icon-container{
    z-index: 10;
    position: absolute;
    margin: 1.2rem;
    right: 0;
}

.input-icon{
    display: flex;
}