/* custom checkmark for checkboxes instructions from: https://www.w3schools.com/howto/howto_css_custom_checkbox.asp */

/* Customize the label (the container) */
.container-label{
  display: block;
  position: relative;
  padding-top: 0.3rem;
  padding-left: 2.4rem;
  margin-top: 0.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

/* Create a custom checkbox */
.checkmark{
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
  background-color: black;
  border: 3px var(--main-color) solid;
  border-radius: 50%;
}

/* On mouse-over, add a yellow background color */
.container-label:hover input ~ .checkmark {
    background-color: var(--main-color-light);
  }

/* When the checkbox is checked, add a yellow background */
.container-label input:checked ~ .checkmark {
    background-color: var(--main-color);
  }

  /*******************************/

 

