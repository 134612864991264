.inv-btn-lg-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 58px;
    margin-top: 1.5rem;
    height: 58px;
    width: 100%;
    max-width: 343px;
    background-color: black;
    border: 3px var(--main-color) solid;
    border-radius: 24px;
    color: var(--main-color);
    font-size: var(--text-main-btn-size);
    font-weight: var(--text--main-btn-weight);
}

.inv-btn-lg-content{
    grid-area: 1 / 1 / 2 / 5;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    z-index: 2;
    padding-bottom: 0.3rem;
}

.text-inv-btn{
    color: var(--main-color);
    font-weight: var(--text-btn-weight);
}

.inv-btn{
    grid-area: 1 /1 / 2 / 5;
}