.not-found-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    width: 100%;
    height: 100%;
    text-align: center;
}

.not-found-logo-container{
    margin-top: 20%;
    max-width: 120px;
}

.not-found-text-container{
    padding: 0 0.5rem;
    max-width: 600px; 
    margin-bottom: 1rem;   
}

.not-found-header{
    text-align: center;
    margin: 1rem 0 1rem 0;
}

.not-found-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    border-radius: 15px;
    width: 200px;
    height: 2.4rem;
    font-weight: var(--text-body-header-weight);
    background-color: var(--main-color);
}

.not-found-btn-text{
    color: var(--dark-color);

}

@media screen and (min-width: 700px) {
.not-found-p{
        font-size: 1rem;
    }
}

