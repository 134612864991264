.bo-date > label {
    color: var(--dark-color);
}

.bo-date-input{
    padding-top: 0.1rem;
    width: 100%;
    border: var(--grey-color) 2px solid;
    height: 2.1rem;
    padding-left: 1rem;
    border-radius: 3px;
}