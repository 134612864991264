.login-bo-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--bo-bg-color);
}

.login-bo-logo-container{
    margin: 120px 0 1.5rem 0;
    width: 40%;
    max-width: 150px;
}

.login-bo-logo{
    width: 100%;
}

.login-bo-header{
    font-size: 1.7rem;
    color: var(--dark-color);
}

#login-bo-form{
    display: flex;
    flex-direction: column;
    align-items:center;
    gap: 0.5rem;
    width: 95%;
    font-weight: var(--text-body-header-weight);
}

.login-bo-btn-container{
    display: flex;
    justify-content: center;
    width: 100%;
}

.login-form{
    margin-top: 1.5rem;
}

 p.forgot-link{
    color: var(--bo-main-color);
    text-align: center;
    margin-top: 1rem;
}
