.search-bar{
    position: relative;

}

.data-result{
    position: absolute;
    margin-top: 5px;
    width: 100%;
    max-height: 200px;
    background-color: var(--light-color);
    border-radius:20px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 5;
}

.data-result::-webkit-scrollbar{
    display: none;
}

.data-item{
    padding: 0.2rem 1rem;
    color: var(--dark-color);
    font-weight: var(--text-body-header-weight);
    display: block;
    background-color: var(--light-color);
    border:none;
    width: 100%;
    text-align: left;
}

.data-item:hover{
    background-color: var(--grey-color);
}

.search{
    align-items: center;
}
.search-icon{
    margin-left: 2.3rem;
}

#clear-btn{
    cursor: pointer;
}




