.app-view-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: var(--dark-color);
}

.app-main-btn-container{
  width: 70%;
  max-width: 343px;
  min-height: 3.625rem;
  max-height: 58px;
  left: 50%;
  z-index: 1;
}

.app-header-container{
  position: relative;
  width: 100%;
}


