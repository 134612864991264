.obstacle-main-container{
 display: flex;
 flex-direction: column;
 align-items: center;
 width:100%;
}

.obstacle-form-container{
    display: flex;
    justify-content: center;
    margin-bottom: 1.3rem;
    width: 100%;
}




