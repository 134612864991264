.end-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center ;
    width: 100%;
    height: 90%;
    text-align: center;
 }

.end-logo-container{
    margin: 3% 0 1rem 0; 
    width: 30%;
    max-width: 120px ; 
}

.end-text-container{
    padding: 0 0.4rem;
    max-width: 350px;
}

.end-link-container{
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
 }

a{
    color: var(--main-color);
}

.end-link-btn{
    width: 45%;
    max-width: 350px;
    margin-bottom: 2rem;
}

.end-p{
    margin-bottom: 1rem;
}

@media screen and (min-width: 1000px) {
    .end-logo-container{ 
        max-width: 180px ; 
    }

    .end-text-container{
        max-width: 500px;
    }
  }