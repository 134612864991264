.photo-input-container{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    color: var(--main-color);
    width: 100%;
    height: 100%;
    font-size: var(--text-main-btn-size);
    font-weight: var(--text--main-btn-weight);
}

.custom-upload-photo{
    display: flex;
    align-items: center;
    padding-top: 0.1rem;
    padding-left: 3.5rem;
    color: inherit;
    font-weight: inherit;
    cursor: pointer;
    width: 100%;
    height: 100%;
    grid-area: 1 / 1 / 2 / 13;
}

#photo-icon{
    margin-left: 2rem;
    grid-area: 1 / 8 / 2 / 9;
}

@media screen and (min-width: 340px) {

    .custom-upload-photo{
        padding-left: 4.3rem;
    }
}

@media screen and (min-width: 350px) {
    #photo-icon{
        margin-left: 2.5rem;
        grid-area: 1 / 8 / 2 / 9;
    }
    .custom-upload-photo{
        padding-left: 5rem;
    }
}

@media screen and (min-width: 360px) {
    #photo-icon{
        margin-left: 9.3rem;
        grid-area: 1 / 6 / 2 / 7;
    }
    .custom-upload-photo{
        padding-left: 5.5rem;
    }
}

@media screen and (min-width: 400px) {
    #photo-icon{
        margin-left: 3.5rem;
        grid-area: 1 / 8 / 2 / 9;
    }
    .custom-upload-photo{
        padding-left: 5.5rem;
    }
}