.btn-bo-lg-container{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 58px;
    height: 45px;
    width: 250px;
    background-color: var(--light-color);
    border: 2px var(--bo-main-color) solid;
    border-radius: 5px;
}

.btn-bo-lg-content{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    z-index: 2;
    padding-bottom: 0.3rem;
}

.btn-bo-lg-text{
    color: var(--bo-main-color);
    font-size: 1rem;
    font-weight: var(--text--body-btn-weight);
    margin-top: 0.5rem;
}
