.main-backoffice-container{
    width: 100%;
    height: 100%;
    background-color: var(--bo-bg-color);
    overflow-y: auto;
    padding: 0.5rem 0;
}

.top-row-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bo-logo-container{
    margin-left: 2rem;
    overflow: hidden;
    /* height: 150px; */
    /* background-color: var(--dark-color); */
}

.link-logout-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.bo-log-out-container{
    margin-right: 2rem;
    width: 8rem;
}

.bo-link-container{
    width: 8rem;
    margin-right: 0.5rem;

}

.img-row-container{
    align-items: center;
}

.bo-img-container{
    width: 100%;
    height: 13rem;
    overflow: hidden;
    background-color: var(--dark-color);
    position: relative;
}
.login-input-container{
    display: block;
    align-items: center;
    width: 90%;
    max-width: 350px;
}
.bo-img{
    width: 100%;
    height: 100%;
     object-fit: cover; /*img needs a set width and height for this to have effect.  */
}

.bo-img-text{
    font-size: var(--text-header-size);
    font-weight: var(--text-header-weight);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
#register-user-form{
    display: flex;
    flex-direction: column;
    align-items:center;
    gap: 0.5rem;
    width: 95%;
    font-weight: var(--text-body-header-weight);
}
.input-row-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    margin-top: 1rem;
}
.register-new-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--bo-bg-color);
}

.bo-filter-municipalities-container{
    width: 16rem;
    height: 4rem;
}
.bo-filter-municipalities-container > .select-container > label {
    color: var(--dark-color);
}

.bo-issues-per-page-container{
    width: 16rem;
}

.bo-searchbar-container{
    width: 16rem;
    height: 4rem;
}

.bo-export-container{
    margin-right: 2rem;
    width: auto;
    height: 1rem;
    margin: auto 0;
}

.bo-date-container{
    width: 10rem;
    height: 4rem;
}

.datatable-row-container{
    align-self: flex-start;
}
.data-table-container{
    align-self: flex-start;
    border-radius: 3px;
    overflow:hidden;
    margin: 1rem 2rem 1rem 2rem;
}


.temp-back-to-app{
    grid-area: 1 / 3 / 2 / 4;
    height: 30px;
}

.temp-text{
    color: var(--dark-color);
}

.bo-input-icon-container{
    z-index: 2;
    position: absolute;
    margin: 0.6rem;
    right: 6%;
}