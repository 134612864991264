.data-table-component-container{
    width: 100%;
}

.bo-data-table{
    border-top: var(--grey-color) 2px solid;
    border-right: var(--grey-color) 2px solid;
    border-left: var(--grey-color) 2px solid;
    border-radius: 5px;
}

.bo-row-img-container{
    width: 200px;
    height: 200px;
    overflow: hidden;
}

.bo-row-img{
    object-fit:scale-down;
}

.bo-iconbutton{
    min-width: 2rem;
}