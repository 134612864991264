#bo-search-issue{
    border: var(--grey-color) 2px solid;
    width: 16rem;
    height: 2.1rem;
    padding-left: 1rem;
    border-radius: 3px;

}
.bo-search > label{
    color: var(--dark-color);
}

.bo-search-bar{
    position: relative;

}

.bo-data-result{
    position: absolute;
    margin-top: 5px;
    width: 100%;
    height: 200px;
    background-color: var(--light-color);
    border: var(--grey-color) 2px solid;
    overflow: hidden;
    overflow-y: auto;
    z-index: 5;
}

.bo-data-result::-webkit-scrollbar{
    display: none;
}

.bo-data-item{
    padding: 0.2rem 1rem;
    color: var(--dark-color);
    z-index: 7;
}

.bo-data-item:hover{
    background-color: var(--grey-color);
}

.danger{
    color: var(--error-color);
}


#bo-search-issue::placeholder{
    font-weight: var(--text-body-weight);
}

#bo-search-issue:focus::placeholder{ 
    color:transparent
  }

.bo-search-icon{
    margin-left: 2.3rem;
}

#clear-btn{
    cursor: pointer;
}




