/* CSS Variables */
#root{
  --main-color: #faef37;
  --main-color-light: #bab898;
  --light-color: #fff;
  --dark-color: #000;
  --grey-color: #cfcfcf;
  --bo-main-color: #ff1acc;
  --bo-bg-color: #f5f5f5;
  --error-color: #FF0000;
  --ok-color: #1ea512;

  --text-header-size: 1.2rem;
  --text-header-weight: 700;
  --text-body-size: 1rem;
  --text-body-weight: 400;
  --text-body-small-size:0.8rem;
  --text-body-header-size: 1.2rem;
  --text-body-header-weight: 700;
  --text-main-btn-size: 1.2rem;
  --text-main-btn-weight: 700; 

}

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html, body, #root {
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  /* from create-react base code */
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
input{
  color: var(--form-text-color);
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}

/* Application global settings */
h1{
font-size: var(--text-header-size);
font-weight: var(--text-header-weight);
color: var(--light-color);
}

p{
  font-size: var(--text-body-size);
  font-weight: var(--text-body-weight);
  color: var(--light-color);
}

span{
  font-size: var(--text-body-size);
  font-weight: var(--text-body-weight);
  color: var(--light-color);
}

label{
  font-size: var(--text-body-size);
  font-weight: var(--text-body-header-weight);
  color: var(--light-color);
}

#form{
  display: flex;
  flex-direction: column;
  align-items:stretch;
  gap: 0.8rem;
  width: 95%;
  font-weight: var(--text-body-header-weight);
}

.form-input{
  padding: 0.5rem;
  border-radius: 20px; 
  width: 100%;
  max-width: 350px;
  border: var(--dark-color) 3px solid;
  font-size: var(--text-body-size);
  font-weight: var(--text-body-header-weight);
}

.form-input:focus{
  outline-width: 2px;
  outline-color: var(--main-color-light);
}

.form-input:focus::placeholder{ 
  color:transparent
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--dark-color);
  font-size: var(--text-body-size);
  font-weight: var(--text-header-weight);
  opacity: 0.8; /* Firefox */
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--dark-color);
  font-size: var(--text-body-size);
  font-weight: var(--text-header-weight);
}


.display-none{
  visibility: hidden;
}

.error-message{
  color: var(--error-color);
  text-align: center;
}

.form-message{
  color: var(--main-color);
  text-align: center;
}

.approved-input{
  border: var(--ok-color) 4px solid;
}
.error-input{
  border: var(--error-color) 4px solid;
}
.fineprint{
  font-size: var(--text-body-small-size);
  color: var(--main-color);
  text-align: center;
  margin: 2rem 0 2rem 0;
  text-decoration: underline;
}

/* SELECT ELEMENT */
.select-container{
  position: relative;
  align-items: center;
  width: 100%;
  max-width: 343px;
}

.select-value{
  margin-left: 1rem;
  color: var(--dark-color);
  font-weight: var(--text-body-header);
  z-index: 5;
}
select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  z-index: 5;
  height: 43px;
  background-color: var(--light-color);
}


/* .selected:active{
  background-color: pink;
} */

.select::after{
  background-color: var(--light-color);
}

select::-ms-expand{
  display: none;
}

.select-bg{
  position: absolute;
  width: 100%;
  max-width: 350px;
  height: 100%;
  background-color: var(--light-color);
  border-radius: 20px;
}

.select-icon-container{
  margin-left: 2.3rem;
  z-index: 3;
}


/* Global MediaQueries */

@media screen and (min-width: 360px) {
  #root{
    --text-header-size: 1.7rem;
    --text-body-small-size:0.8rem;
    --text-main-btn-size: 1.5rem;
  }

  #form{
    align-items:center;
    gap: 0.8rem;
    width: 95%;
  }
  
  .form-input{
    padding: 0.8rem;
    border-radius: 20px; 
    width: 350px;
    min-height: 3.4rem;
  }

  .select-bg{
    height: 3.2rem;
  }
}

@media screen and (min-width: 400px) {
  #root{
    --text-header-size: 2rem;
    --text-body-size: 1rem;
    --text-body-small-size:0.8rem;
  }

  #form{
    align-items:center;
    gap: 0.8rem;
    width: 95%;
  }

  #obstacle-desc{
    height: 6rem;
  }

  .select-bg{
    height: 3.2rem;
  }
}

@media screen and (min-width: 700px) {
  #root{
    --text-header-size: 2.2rem;
    --text-main-btn-size: 1.5rem;
    --text-body-small-size: 1rem;

  }

  #form{
    align-items:center;
    gap: 1.2rem;
  }
  
  .form-input{
    padding: 0.8rem;
    border-radius: 20px; 
    width: 350px;
    height: 4rem;
  }

  .select-bg{
    height: 3.7em;
  }

  #obstacle-desc{
    height: 6.5rem;
  }

}

@media screen and (min-width: 1150px) {
  #root{
    --text-header-size: 2.3rem;
    --text-body-size: 1rem;
    --text-body-small-size: 1rem;
    --text-main-btn-size: 1.5rem;
  }

  #form{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5rem;
  }
 
}

@media screen and (min-width: 1466px) {
  #form{
    width: 85%;
    gap: 2rem;
  }
}

@media screen and (min-width: 1486px) {
  #form{
    width: 75%;
    gap: 2rem;
  }
}

