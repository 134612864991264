.gdpr-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    width: 100%;
    height: 100%;
    text-align: center;
}

.gdpr-logo-container{
    max-width: 120px;
    margin-top: 8%;
}

.gdpr-text-container{
    padding: 0 0.5rem;
    max-width: 500px;    
}

.gdpr-header{
    text-align: center;
}

.gdpr-p{
    font-size: 0.8rem;
    
}

.gdpr-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    border-radius: 15px;
    width: 200px;
    height: 2.4rem;
    font-weight: var(--text-body-header-weight);
    background-color: var(--main-color);
}

.gdpr-btn-text{
    color: var(--dark-color);

}

@media screen and (min-width: 700px) {
    .gdpr-p{
        font-size: 1rem;
    }
}

