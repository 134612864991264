.login-input-container{
    display: grid;
    align-items: center;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 1fr;
    width: 90%;
    max-width: 350px;
}

.login-input{
    padding: 0.5rem;
    border-radius: 5px; 
    width: 100%;
    max-width: 3%px;
    border: var(--grey-color) 2px solid;
    font-size: 1.2rem;
    font-weight: var(--text-body-header-weight);
}
.login-input-label {
    display: block;       /* Ensures the label is on its own line above the input */
    color: black;         /* Sets the text color to black */
    margin-bottom: 8px;   /* Adds a little space between the label and the input field */
    font-weight: bold;    /* Makes the text a bit heavier for emphasis */
    font-size: 14px;      /* Sets a standard readable font size */
}
.login-input:focus::placeholder{
    color: transparent;
}

.login-input::placeholder{
    font-size: 1rem;
}

#login-username,
#login-password{
    grid-area: 1 / 1 / 2 / 5;
}

.login-icon-container{
    margin-left: 2.3rem;
    grid-area: 1 / 4 / 2 / 5 ;
    z-index: 2;
}